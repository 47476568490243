
import { Component, Vue } from 'vue-property-decorator';
import MobileView from '@/views/mobile/MobileView.vue';

@Component({
  components: {
    MobileView,
  },
})
export default class App extends Vue {
  showRoutes: boolean = true;
  time = 0;
  timer: any = null;

  created() {
    this.deviceCheck();
    const payload = {
      token: this.jwtToken,
      userId: this.userId,
    }
    this.$store.dispatch('socketStore/addSocketEvent', payload);
    this.$store.dispatch('downloadEventHandler/downloadEventListener');
  }

  get jwtToken() {
    return this.$store.getters['token'];
  }

  get userId() {
    return this.$store.getters["userId"];
  }

  beforeDestroy() {
    this.$store.dispatch('socketStore/socketDestroy')
  }

  /**
   * @description: 사용기기 체크
   * 모바일일 경우 모바일 컴포넌트 보여주기 (MobileView)
   * @private
   */
  private deviceCheck() {
    const { userAgent } = window.navigator;
    this.showRoutes = this.isMobile(userAgent);
  }

  private isMobile(agent: string): boolean {
    const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return !reg.test(agent);
  }
}
