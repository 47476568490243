
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import type {IQuestionValue} from '@/interface/survey/question';
import {QUESTION} from '@/types/question';
import OptionRadio from '@/components/project/make/options/OptionRadio.vue';
import OptionRadioSet from '@/components/project/make/options/OptionRadioSet.vue';
import OptionRadioSets from '@/components/project/make/options/OptionRadioSets.vue';
import OptionCheck from '@/components/project/make/options/OptionCheck.vue';
import OptionCheckSets from '@/components/project/make/options/OptionCheckSets.vue';
import OptionGradeClick from '@/components/project/make/options/OptionGradeClick.vue';
import OptionMultiText from '@/components/project/make/options/OptionMultiText.vue';
import OptionTextArea from '@/components/project/make/options/OptionTextArea.vue';
import OptionInfoTitle from '@/components/project/make/options/OptionInfoTitle.vue';
import OptionInfoDesc from '@/components/project/make/options/OptionInfoDesc.vue';
import _ from 'lodash';
import {ToastMessage, ToastVariant} from '@/utils/ToastEnum';
import PrevArrow from "@/components/project/make/options/common/icons/PrevArrow.vue";
import NextArrow from "@/components/project/make/options/common/icons/NextArrow.vue";
import {FileUploadPayload} from "@/interface/make/properties";
import EventBus from '@/utils/EventBus';

@Component({
  components: {
    NextArrow,
    PrevArrow,
    OptionRadio,
    OptionRadioSet,
    OptionRadioSets,
    OptionCheck,
    OptionCheckSets,
    OptionGradeClick,
    OptionMultiText,
    OptionTextArea,
    OptionInfoTitle,
    OptionInfoDesc,
  },
})
export default class QuestionEdit extends Vue {
  @Prop({ required: true }) readonly data: IQuestionValue;
  @Prop({ default: [] }) readonly list: IQuestionValue[];
  @Prop() readonly dataIndex: number;
  @Prop() readonly questionName: string[];

  question: IQuestionValue = QUESTION.tempQuestionData();
  QUESTION: Object = QUESTION;
  pending = false;

  created() {
    this.watchData();
  }

  @Watch('data')
  watchData() {
    this.question = _.cloneDeep(this.data);
  }

  //문항 타입이 변경된 경우 변경된 문항 필드만 가져오도록 수정
  questionTypeChange() {
    const {TYPE} = this.question;
    const defaultQuestion: IQuestionValue = QUESTION.defaultQuestionData(TYPE as QUESTION.QUESTION_TYPES);
    for (let key of Object.keys(defaultQuestion)) {
      if (this.question.hasOwnProperty(key)) {
        defaultQuestion[key] = this.question[key];
      }
    }
    this.question = defaultQuestion;

    // TITLE, DESC 고정 이름
    this.defaultTitle(TYPE);
  }

  //TITLE, DESC default TITLE
  defaultTitle(TYPE: string) {
    switch (TYPE) {
      case QUESTION.QUESTION_TYPES.TITLE:
        this.question.QUESTION = QUESTION.QUESTION_TYPES.TITLE;
        break;
      case QUESTION.QUESTION_TYPES.DESC:
        this.question.QUESTION = QUESTION.QUESTION_TYPES.DESC;
        break;
    }
  }

  // DESC 문항일 경우 border 검사
  descBorderCheck(): boolean {
    let result = true;
    const {BORDER} = this.question;
    if (BORDER) {
      const border = BORDER?.split(' ').filter((v) => v.length !== 0);
      if (border.length < 3 && border.length > 1) {
        this.$common.makeToast(ToastMessage.BORDER_OPTION_MESSAGE, ToastVariant.WARNING, this.$bvToast);
        result = false;
      }
    }
    return result;
  }

  // 이전 문항으로
  async prevQuestion() {
    this.$emit('btnHover', true);

    // 현재 생성된 문항 이름 그룹
    const {NAME} = this.question; // 현재 선택된 문항 정보
    const index = this.questionName.findIndex((value) => value === NAME) - 1;
    await this.setMoveButton(index);
  }

  // 다음 문항으로
  async nextQuestion() {
    this.$emit('btnHover', true);

    const {NAME} = this.question;
    let index = this.questionName.findIndex((value) => value === NAME) + 1;
    const totalLength = this.questionName.length;
    if (totalLength === index) index -= 1;
    await this.setMoveButton(index);
  }

  async setMoveButton(index: number) {
    this.$emit('questionSwitch', this.questionName[index]);
  }

  // 입력란 넓이 최대 700으로 제한
  widthCheck() {
    const textWidth = Number(this.question.WIDTH);
    if (textWidth > 700 || textWidth < 200 || isNaN(textWidth)) {
      const inputElement = document.body.querySelector('[name="data-width"]') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
      this.$common.makeToast(ToastMessage.MAX_WIDTH_CHECK, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }

  // 옵션 별 개수 입력 검사
  textValidation() {
    const {
      MIN_LENGTH,
      MIN_COUNT,
      MAX_LENGTH,
      MAX_COUNT,
      TYPE,
      MAX_CHECK,
      MIN_CHECK,
      MAX_GRADE,
      MIN_GRADE,
      ANSWER_CHECK,
    } = this.question;
    if (TYPE === QUESTION.QUESTION_TYPES.MULTI_TEXT) {
      return (
        this.widthCheck() &&
        this.textLengthCheck(MIN_LENGTH, MAX_LENGTH) &&
        this.responseCountCheck(MIN_COUNT, MAX_COUNT, ANSWER_CHECK) &&
        this.textResponseCountCheck(MIN_COUNT, MAX_COUNT)
      );
    } else if (TYPE === QUESTION.QUESTION_TYPES.TEXTAREA) {
      return this.textLengthCheck(MIN_LENGTH, MAX_LENGTH);
    } else if (TYPE === QUESTION.QUESTION_TYPES.CHECK || TYPE === QUESTION.QUESTION_TYPES.CHECKSETS) {
      return this.responseCountCheck(MIN_CHECK, MAX_CHECK, ANSWER_CHECK);
    } else if (TYPE === QUESTION.QUESTION_TYPES.GRADE_CLICK) {
      if (!MIN_GRADE) this.question.MIN_GRADE = '1';
      return this.responseCountCheck(MIN_GRADE, MAX_GRADE, ANSWER_CHECK);
    } else {
      return this.responseCountCheck(MIN_GRADE, MAX_GRADE, ANSWER_CHECK);
    }
  }

  // 글자 개수 검사
  textLengthCheck(MIN, MAX): boolean {
    if (!+MIN! || !+MAX!) {
      const inputElement = document.body.querySelector('[name="min-value-2"]') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
      this.$common.makeToast(ToastMessage.MIN_NOT_ZERO, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    if (+MIN! >= +MAX!) {
      const inputElement = document.body.querySelector('[name="max-value-2"]') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }

      this.$common.makeToast(ToastMessage.MIN_MAX_NOT_SAME, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }

  textResponseCountCheck(MIN, MAX): boolean {
    // 응답 개수 10개로 제한
    if (+MIN > 10 || +MAX > 10) {
      const inputElement = document.body.querySelector('[name="max-value-1"]') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
      this.$common.makeToast(ToastMessage.RESPONSE_COUNT_MAXIMUM_10, ToastVariant.DANGER, this.$bvToast);
      return false;
    } else return true;
  }

  // 응답 개수 검사
  responseCountCheck(MIN, MAX, ANSWER_CHECK): boolean {
    if (MIN)
      if (!+MIN && !MIN && !ANSWER_CHECK) {
        this.$common.makeToast(ToastMessage.RESPONSE_COUNT_NOT_ZERO, ToastVariant.DANGER, this.$bvToast);
        return false;
      }

    if (+MIN > +MAX) {
      this.$common.makeToast(ToastMessage.RESPONSE_MIN_MAX_NOT_SAME, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }


  /**
   * @param {boolean} type 행렬형, 복수 행렬형 check
   *  type = true 일 경우 해당 검사 식은 행렬형 혹은 복수 행렬형을 의미
   * @description 대,중,소 분류 비어있는 값 검사
   */
  categoryValidation(type?: boolean): boolean {
    const c1: string[] = [];
    const c2: string[] = [];
    const c3: string[] = [];
    const exampleCount = this.question.ANSWERS?.length || this.question.V?.length;
    const columns = this.question.H?.length;

    // 단일 선택(RADIO), 복수 선택(RADIOSETS) ANSWERS
    this.question.ANSWERS?.forEach(v => {
      if (v?.C1 && v?.C1.length > 0) c1.push(v.C1);
      if (v?.C2 && v?.C2.length > 0) c2.push(v.C2);
      if (v?.C3 && v?.C3.length > 0) c3.push(v.C3);
    });

    // 순위형(GRADE_CLICK) V
    this.question.V?.forEach(v => {
      if (v?.C1 && v?.C1.length > 0) c1.push(v.C1);
    });
    // 행렬형, 복수 행렬형 V H
    this.question.H?.forEach(v => {
      if (v?.C1 && v?.C1.length > 0) c2.push(v.C1);
    });
    if (type) return (c1.length > 0 && (c1.length !== exampleCount)) || (c2.length > 0 && (c2.length !== columns))
    return (c1.length > 0 && (c1.length !== exampleCount)) || (c2.length > 0 && (c2.length !== exampleCount)) || (c3.length > 0 && (c3.length !== exampleCount));
  }

  questionValidation(): boolean {
    if (!this.emptyTextCheck()) return false;
    const {TYPE} = this.question;
    switch (TYPE) {
      case QUESTION.QUESTION_TYPES.RADIOSETS:
        if (this.categoryValidation(true)) {
          this.$common.makeToast(ToastMessage.VALIDATION_ERROR_NOT_EMPTY_CATEGORY, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
        return true;
      case QUESTION.QUESTION_TYPES.RADIO:
        if (this.categoryValidation()) {
          this.$common.makeToast(ToastMessage.VALIDATION_ERROR_NOT_EMPTY_CATEGORY, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
        return true;
      case QUESTION.QUESTION_TYPES.MULTI_TEXT:
      case QUESTION.QUESTION_TYPES.TEXTAREA:
      case QUESTION.QUESTION_TYPES.GRADE_CLICK: {
        if (TYPE === QUESTION.QUESTION_TYPES.GRADE_CLICK && this.categoryValidation()) {
          this.$common.makeToast(ToastMessage.VALIDATION_ERROR_NOT_EMPTY_CATEGORY, ToastVariant.DANGER, this.$bvToast);
          return false;
        }

        return this.textValidation();
      }
      case QUESTION.QUESTION_TYPES.CHECK: {
        // 복수 문항 최대 응답 개수 확인
        const TOTAL = this.question.ANSWERS!.length;
        const {MAX_CHECK, MIN_CHECK} = this.question;
        if (!MAX_CHECK) this.question.MAX_CHECK = TOTAL.toString();
        if (!MIN_CHECK) this.question.MIN_CHECK = '1';

        if (Number(MAX_CHECK) > TOTAL) {
          this.$common.makeToast(ToastMessage.MAX_COUNT_OVER, ToastVariant.DANGER, this.$bvToast);
          return false;
        }

        if (this.categoryValidation()) {
          this.$common.makeToast(ToastMessage.VALIDATION_ERROR_NOT_EMPTY_CATEGORY, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
        return this.textValidation();
      }
      case QUESTION.QUESTION_TYPES.CHECKSETS: {
        const TOTAL = this.question.V!.length * this.question.H!.length;
        const {MAX_CHECK, MIN_CHECK} = this.question;
        if (!MAX_CHECK) this.question.MAX_CHECK = TOTAL.toString();
        if (!MIN_CHECK) this.question.MIN_CHECK = '1';

        if (Number(MAX_CHECK) > TOTAL) {
          this.$common.makeToast(ToastMessage.MAX_COUNT_OVER, ToastVariant.DANGER, this.$bvToast);
          return false;
        }

        if (this.categoryValidation(true)) {
          this.$common.makeToast(ToastMessage.VALIDATION_ERROR_NOT_EMPTY_CATEGORY, ToastVariant.DANGER, this.$bvToast);
          return false;
        }

        return this.textValidation();
      }
      case QUESTION.QUESTION_TYPES.DESC: {
        // 안내문 border 체크
        return this.descBorderCheck();
      }
      default: {
        return true;
      }
    }
  }

  emptyTextCheck(): boolean {
    // 질문 체크
    if (this.question.QUESTION.length === 0) {
      this.$common.makeToast("질문을 입력해주세요.", ToastVariant.DANGER, this.$bvToast);
      EventBus.$emit('focusEvent');
      return false;
    }

    const checkEmptyAndDuplicate = (arr: any[], refClass: string): boolean => {
      const emptyIndex = arr.findIndex(answer => answer.V.length === 0);

      if (emptyIndex !== -1) {
        this.$common.makeToast("보기를 입력해주세요.", ToastVariant.DANGER, this.$bvToast);
        const emptyText = document.body.querySelector(`.${refClass}_${emptyIndex}`) as HTMLTextAreaElement;
        if (emptyText) emptyText.focus();
        return false;
      }

      if (arr.length < 2) {
        this.$common.makeToast("2 개 이상 보기를 입력해주세요.", ToastVariant.DANGER, this.$bvToast);
        return false;
      }

      const values = arr.map(answer => answer.V);
      const hasDuplicates = new Set(values).size !== values.length;
      if (hasDuplicates) {
        const lastIndex = values.reduce((lastIdx, currentValue, index) => {
          return values.lastIndexOf(currentValue) === index ? lastIdx : index;
        }, -1);
        this.$common.makeToast("보기 항목의 내용이 중복되었습니다. 한 번 더 확인해 주십시오.", ToastVariant.DANGER, this.$bvToast);
        const duplicateText = document.body.querySelector(`.${refClass}_${lastIndex}`) as HTMLTextAreaElement;
        if (duplicateText) duplicateText.focus();
        return false;
      }

      return true;
    };

    // ANSWERS, V, H 배열 체크
    if (this.question.ANSWERS && !checkEmptyAndDuplicate(this.question.ANSWERS, 'textarea')) return false;
    if (this.question.V && !checkEmptyAndDuplicate(this.question.V, 'row_textarea')) return false;
    if (this.question.H && !checkEmptyAndDuplicate(this.question.H, 'col_textarea')) return false;

    return true;
  }

  /**
   * 문항 수정 저장
   */
  async save(type?: string): Promise<void> {
    this.pending = true;
    try {
      const result = this.questionValidation();
      if (result) {
        await this.fileCheck();
        await this.$question.save(this.question, type);
      }
    } catch (e) {
      this.pending = false;
    } finally {
      this.pending = false;
    }
  }

  /**
   * @description: 단수형은 ANSWERS 복수형은 V 로 들어간다 ㄱ-
   *  key가 ANSWERS : 단일 선택, 복수 선택, 척도
   *        V : 순위형, 행렬형, 복수 행렬형
   *        DATA : 안내문
   */
  async fileCheck(): Promise<void> {
    try {
      const {id: snum} = this.$route.params as unknown as {id: string};
      const questionFileCheck = this.$store.getters['makeStore/getQuestionFileLists'];
      // 질문
      if (questionFileCheck.length > 0) await this.questionTitleImageUpload(snum, questionFileCheck);
      // ANSWERS : 단일 선택, 복수 선택, 척도
      await this.questionSingleUpload(snum);
      //  V : 순위형, 행렬형, 복수 행렬형
      await this.questionMatrixUpload(snum);
      // DATA : 안내문
      await this.questionDescUpload(snum);
    } catch (e) {
      this.$common.makeToast(`파일 업로드에 실패하였습니다.`, ToastVariant.DANGER, this.$bvToast);
    } finally {
      await this.$store.dispatch('makeStore/fileStateReset');
    }
  }

  // 질문 이미지 업로드
  async questionTitleImageUpload(snum: string, questionFileCheck: FileUploadPayload[]): Promise<void> {
    const {dataUrl, file} = questionFileCheck[0] as {dataUrl: string[], file: File[]}
    const data = await this.uploadImage(snum, file[0]);
    if (data) {
      const {Location} = data[0];
      this.question.QUESTION = await this.getReplacer(this.question.QUESTION, dataUrl, Location);
      this.question.IMG_LOCATION = Location;
    }
    delete this.question.FILE;
  }
  // 단일 선택, 복수 선택, 척도형 보기 옵션 이미지 업로드
  async questionSingleUpload(snum: string): Promise<void> {
    try {
      if (this.question.ANSWERS) {
        for (let i = 0; i < this.question.ANSWERS?.length; i++) {
          const answer = this.question.ANSWERS[i];
          if (answer.FILE) {
            const {origin} = answer.FILE;
            const data = await this.uploadImage(snum, origin);
            if (data.length > 0) {
              const {Location} = data[0];
              this.question.ANSWERS[i].V = this.textCombined(Location, answer.V);
              this.question.ANSWERS[i].IMG_LOCATION = Location;
            }
          } else if (answer.IMG_LOCATION) {
            this.question.ANSWERS[i].V = this.textCombined(answer.IMG_LOCATION, answer.V);
          } else {
            this.question.ANSWERS[i].V = this.$common.removeImageTag(answer.V);
          }
          delete this.question.ANSWERS[i].FILE;
        }
      }
    } catch (e) {
      throw e;
    }
  }

  // 순위형, 행렬형, 복수 행렬형 이미지 업로드
  async questionMatrixUpload(snum: string): Promise<void> {
    try {
      if (this.question.V) {
        for (let i = 0; i < this.question.V?.length; i++) {
          const answer = this.question.V[i];
          if (answer.FILE) {
            const {origin} = answer.FILE;
            const data = await this.uploadImage(snum, origin);
            if (data.length > 0) {
              const {Location} = data[0];
              this.question.V[i].V = this.textCombined(Location, answer.V);
              this.question.V[i].IMG_LOCATION = Location;
            }
          } else if (answer.IMG_LOCATION) {
            this.question.V[i].V = this.textCombined(answer.IMG_LOCATION, answer.V);
          } else {
            this.question.V[i].V = this.$common.removeImageTag(answer.V);
          }
          delete this.question.V[i].FILE;
        }
      }
    } catch (e) {
      throw e;
    }
  }
  // 안내문 이미지 업로드
  async questionDescUpload(snum: string): Promise<void> {
    try {
      if (this.question.DATA) {
        for (let i = 0; i < this.question.DATA?.length; i++) {
          const answer = this.question.DATA[i];
          let text = '';
          if (answer.HTML) text = answer.HTML.replaceAll(/(\n|\r\n)/g, '<br>');
          if (answer.FILE) {
            const {origin} = answer.FILE;

            const data = await this.uploadImage(snum, origin);
            if (data.length > 0) {
              const {Location} = data[0];
              this.question.DATA[i].HTML = this.textCombinedInDesc(Location, text || '');
              this.question.DATA[i].IMG_LOCATION = Location;
            }
            delete this.question.DATA[i].FILE;
          } else if (answer.IMG_LOCATION) {
            this.question.DATA[i].HTML = this.textCombinedInDesc(answer.IMG_LOCATION, text || '');
          } else {
            this.question.DATA[i].HTML = this.$common.removeImageTag(text || '');
          }
        }
      }
    } catch (e) {
      throw e;
    }
  }

  async uploadImage(snum: string, file: File) {
    try {
      const formDataQuestion = new FormData();
      formDataQuestion.append('fileList', file);
      const {data} = await this.requestUploadFile(snum, formDataQuestion);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async requestUploadFile(snum: string, formData: FormData) {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }
      const url = `/project/make/file-upload/${snum}`;
      return this.axios.post(url, formData, {headers});
    } catch (e) {
      throw e;
    }
  }

  /**
   * @description: 이미지 태그와 text 합치기
   * @param location
   * @param value
   */
  textCombined(location: string, value: string): string {
    const imageTag = this.$common.imageTagGenerator(location);
    const plainText = this.$common.removeImageTag(value);
    return imageTag + plainText;
  }

  /**
   * @description: 이미지 태그와 text 합치기
   * @param location
   * @param value
   */
  textCombinedInDesc(location: string, value: string): string {
    const imageTag = this.$common.imageTagGeneratorInDesc(location);
    const plainText = this.$common.removeImageTag(value);
    return imageTag + plainText;
  }

  async getReplacer(content: string, dataUrlList: string[], Location: string): Promise<string> {
    if (dataUrlList.length) {
      dataUrlList.forEach((dataUrl) => {
        content = content.replace(dataUrl, Location);
      });
    }
    return content;
  }


  cancel() {
    return this.$emit('cancel');
  }

  /**
   * 복사 문항 속해있는지 확인
   */
  isChild() {
    const {CHILD_NAMES = [], PARENT_NAME = ''} = this.question;
    return CHILD_NAMES.length > 0 || PARENT_NAME !== '';
  }

}
