
import { Vue, Component } from 'vue-property-decorator';
import { HREF } from '@/types/constant';

@Component
export default class Views extends Vue {
  async created() {
    if (!this.ACCESS_KEY) return await this.loginPage();
    const link = await this.getRedirectLink(this.ACCESS_KEY);

    if (!link) return await this.loginPage();
    window.location.href = link;
  }

  get ACCESS_KEY() {
    return this.$route.query.ACCESS_KEY;
  }

  get UID() {
    return this.$route.query.UID;
  }

  get GROUP_ID() {
    return this.$route.query.GROUP_ID;
  }

  async getRedirectLink(ACCESS_KEY) {
    try {
      const { data: link } = await this.axios({
        url: '/join/in',
        method: 'get',
        params: {
          ACCESS_KEY,
          UID: this.UID,
          GROUP_ID: this.GROUP_ID,
        },
      });

      return link;
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  async landingPage() {
    await window.location.replace(HREF.LANDING);
  }

  async loginPage() {
    await this.$router.replace('login');
  }
}
