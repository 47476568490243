
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import type { IQuestionValue } from '@/interface/survey/question';
import { QUESTION } from '@/types/question';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import MenuIcon from "@/components/icons/project/MenuIcon.vue";

@Component({
  components: {
    MenuIcon,
    draggable,
  },
})
export default class QuestionNavigation extends Vue {
  @Prop({ default: [] }) readonly list: IQuestionValue[];
  @Prop({ default: '' }) readonly currentDataName: string;
  @Prop({ default: false }) readonly isDragFalse: boolean;
  @Prop({ default: 0 }) readonly linkConfirmation: number;
  @Prop({ default: '' }) readonly componentType: string;
  @Prop() readonly singleView: boolean;

  placeholder = '<div>문항 제목을 입력해주세요.</div>';
  questionNavigationHover = '';
  loading = true;
  tempList: IQuestionValue[] = [];
  dragOptions: Object = {
    animation: 200,
    group: 'navigation',
    disabled: false,
    ghostClass: 'ghost',
    forceFallback: true,
  };
  questionNotClick = false;

  mounted() {
    this.$root.$on('bv::dropdown::show', (bvEvent) => {
      this.questionNotClick = true;
    });

    this.$root.$on('bv::dropdown::hide', (bvEvent) => {
      this.questionNotClick = false;
    });

    this.init();
  }

  redirectQuestion(name: string) {
    if (this.questionNotClick) return;
    this.$emit('questionSwitch', { QNUM: name, naviScroll: false });
  }

  questionHover(qnum: string) {
    this.questionHoverComputed = qnum;
  }

  set questionHoverComputed(type: string) {
    this.questionNavigationHover = type;
  }
  get questionHoverComputed() {
    return this.questionNavigationHover;
  }

  removeImageAndSpaceTag(contents: string): string {
    return this.$common.removeImageTagInContents(contents, /<IMG(.*?)>|<BR(.*?)>|<DIV(.*?)>|<\/DIV(.*?)>/gi, '')
  }

  fixedCheck(qnum: string) {
    const fixedIndex = QUESTION.FIXED_QUESTIONS.indexOf(qnum);
    return fixedIndex > -1;
  }

  convertFixedQuestion(qItem): string {
    switch (qItem.QNUM) {
      case QUESTION.FIXED_QUESTIONS[0]:
        return '성별';
      case QUESTION.FIXED_QUESTIONS[1]:
        return '나이';
      case QUESTION.FIXED_QUESTIONS[2]:
        return '지역';
      default:
        return qItem.QNUM || qItem.NAME;
    }
  }

  questionDelete(qnum: string) {
    const fixedIndex = QUESTION.FIXED_QUESTIONS.indexOf(qnum);
    if (fixedIndex > -1) {
      this.$common.makeToast(`[${qnum}]  ${ToastMessage.QUESTION_NOT_DELETE}`, ToastVariant.DANGER, this.$bvToast);
    } else {
      this.$emit('modalController', { type: QUESTION.MODAL_TYPE.DELETE, qnum });
      this.propListUpdate();
    }
  }

  questionCopy(qnum: string) {
    const fixedIndex = QUESTION.FIXED_QUESTIONS.indexOf(qnum);
    if (fixedIndex > -1) {
      this.$common.makeToast(`[${qnum}]  ${ToastMessage.QUESTION_NOT_COPY}`, ToastVariant.DANGER, this.$bvToast);
    } else {
      this.$emit('modalController', { type: QUESTION.MODAL_TYPE.COPY, qnum });
      this.propListUpdate();
    }
  }

  questionMove(qnum: string) {
    const fixedIndex = QUESTION.FIXED_QUESTIONS.indexOf(qnum);
    if (fixedIndex > -1) {
      this.$common.makeToast(`[${qnum}]  ${ToastMessage.QUESTION_NOT_MOVE}`, ToastVariant.DANGER, this.$bvToast);
    } else {
      this.$emit('modalController', { type: QUESTION.MODAL_TYPE.MOVE, qnum });
      this.propListUpdate();
    }
  }

  async navigationSort({ moved }) {
    const { newIndex, oldIndex } = moved;
    try {
      const { NAME: name } = this.list[oldIndex];
      const { FIXED_QUESTIONS } = QUESTION;
      // newIndex 0번쨰면 타 공백으로 바까줌, 대상자 문항 뒤에 올 수 있도록
      const cursor = newIndex < 1 ? '' : this.list[oldIndex > newIndex ? newIndex - 1 : newIndex].NAME;

      if (FIXED_QUESTIONS.indexOf(name) > -1) {
        this.$common.makeToast(`고정 문항 [${name}]은 이동할 수 없습니다`, ToastVariant.DANGER, this.$bvToast);
        this.propListUpdate();
        return;
      }

      //문항이동
      const result = await this.$question.move(name, cursor);
      if (!result) {
        //실패하면 리스트 순서 돌려놓기
        this.propListUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Watch('list')
  propListUpdate() {
    this.tempList = this.list;
  }

  async init() {
    this.loading = true;
    this.tempList = this.list;
    this.loading = false;
  }

  get dragClass(): string {
    return this.isDragFalse ? '' : 'handle';
  }
}
